// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-fleet-tsx": () => import("./../../../src/pages/fleet.tsx" /* webpackChunkName: "component---src-pages-fleet-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-templates-aircraft-tsx": () => import("./../../../src/templates/aircraft.tsx" /* webpackChunkName: "component---src-templates-aircraft-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-partner-tsx": () => import("./../../../src/templates/partner.tsx" /* webpackChunkName: "component---src-templates-partner-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */)
}

